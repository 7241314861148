import { findAll, isUnattached, setAttached } from "./dom";

export default () =>
  findAll(document, "[data-gk-toggle]")
    .filter((element) => isUnattached("toggle", element))
    .map((element) => setAttached("toggle", element))
    .forEach((element) => {
      element.addEventListener("click", () => {
        const target = document.querySelector(
          element.getAttribute("data-gk-toggle")
        );
        target.classList.toggle("is-hidden");
      });
    });
