import { Controller } from "@hotwired/stimulus";
import { enter, leave } from "el-transition";

export default class extends Controller {
  static targets = ["wrapper", "dialog", "frame"];
  static values = {
    show: Boolean,
  };

  initialize() {
    this.visible = false;

    const link = document.querySelector(".cookie-consent-link");

    if (link) {
      link.addEventListener("click", (e) => {
        e.preventDefault();
        this.show();
      });
    }

    if (this.showValue) {
      this.show();
    }
  }

  show() {
    if (!this.visible) {
      this.visible = true;

      document.body.classList.add("consent-scroll-lock");

      enter(this.wrapperTarget);
      enter(this.dialogTarget);
    }
  }

  hide(event) {
    if (
      this.visible &&
      (event.type !== "turbo:submit-end" || event.detail.success) &&
      (event.type !== "keydown" || event.key === "Escape")
    ) {
      document.body.classList.remove("consent-scroll-lock");

      leave(this.dialogTarget);
      leave(this.wrapperTarget).then(() => {
        this.visible = false;
      });
    }
  }
}
