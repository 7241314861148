import { Tooltip } from "bootstrap";
import { findAll, isUnattached, setAttached } from "./dom";

export default () =>
  findAll(document, '[data-bs-toggle="tooltip"]')
    .filter((element) => isUnattached("tooltips", element))
    .map((element) => setAttached("tooltips", element))
    .forEach((element) => {
      new Tooltip(element, {
        container: element.parentNode,
      });
    });
