import { findAll, isUnattached, setAttached } from "./dom";

const calculateTotal = (calculator) => {
  const items = findAll(calculator, ".gk-subtotal-item");
  let total = 0;
  let currency = "";

  items.forEach((item) => {
    const quantity = item.querySelector(".gk-subtotal-quantity");
    const cost = item.querySelector(".gk-subtotal-cost");
    currency = item.dataset.gkDisplayCurrency;
    total += quantity.value * cost.value;
  });

  // const totalShippingCost = document.querySelector(
  //   "#purchase_order_shipping_cost"
  // ).value;
  //
  // if (totalShippingCost !== "") {
  //   total += parseFloat(totalShippingCost);
  // }

  calculator.querySelector(
    ".gk-total"
  ).innerText = `Total: ${currency} ${total.toLocaleString("da-DK", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
  })}`;
};

const calculateShippingCostPerBottle = () => {
  const totalShippingCost = document.querySelector(
    "#purchase_order_shipping_cost"
  ).value;

  let totalQuantity = 0;

  document.querySelectorAll(".gk-subtotal-quantity").forEach((element) => {
    if (element.value !== "") {
      totalQuantity += parseInt(element.value);
    }
  });

  if (totalQuantity === 0) {
    return 0;
  }

  return totalShippingCost / totalQuantity;
};

const calculateProfit = (parent) => {
  const quantity = parent.querySelector(".gk-subtotal-quantity");
  const revenue = parent.querySelector(".gk-subtotal-revenue");
  const cost = parent.querySelector(".gk-subtotal-cost");
  const duty = parent.querySelector(".gk-subtotal-duty");
  const dutyCost = parent.querySelector(".gk-subtotal-duty-cost");

  if (quantity.value == 0 || revenue.value === "" || cost.value === "") {
    return null;
  }

  const revenuePerBottle = revenue.value;
  const costPerBottle = cost.value;
  const dutyCostPerBottle = dutyCost.value;

  const exchangeRate = parent.dataset.gkExchangeRate;

  const exchangedCostPerBottle = costPerBottle * exchangeRate;
  const exchangedDutyCostPerBottle = dutyCostPerBottle * exchangeRate;
  const exchangedShippingCostPerBottle =
    calculateShippingCostPerBottle() * exchangeRate;

  const totalCostPerBottle =
    exchangedCostPerBottle +
    exchangedDutyCostPerBottle +
    exchangedShippingCostPerBottle;

  if (duty.value === "full") {
    return (
      ((revenuePerBottle * 0.8 - totalCostPerBottle) / revenuePerBottle) * 100
    );
  } else {
    return (
      (((revenuePerBottle - totalCostPerBottle) * 0.8) / revenuePerBottle) * 100
    );
  }
};

const handleChange = (event) => {
  const parent = event.target.closest(".gk-subtotal-item");
  const quantity = parent.querySelector(".gk-subtotal-quantity");
  const cost = parent.querySelector(".gk-subtotal-cost");
  const subtotal = parent.querySelector(".gk-subtotal");
  const profit = parent.querySelector(".gk-profit");
  const currency = parent.dataset.gkDisplayCurrency;
  const subtotalValue = quantity.value * cost.value;

  const profitValue = calculateProfit(parent);

  if (profitValue !== null) {
    profit.innerText = `Avance: ${profitValue.toLocaleString("da-DK", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 0,
    })}%`;
  } else {
    profit.innerText = "";
  }

  subtotal.innerText = `Subtotal: ${currency} ${subtotalValue.toLocaleString(
    "da-DK",
    { maximumFractionDigits: 2, minimumFractionDigits: 0 }
  )}`;

  calculateTotal(parent.closest(".gk-total-calculator"));
};

const handleGlobalChange = () => {
  findAll(document, ".gk-subtotal-item").forEach((item) => {
    handleChange({ target: item });
  });
};

export default () => {
  findAll(document, ".gk-total-calculator")
    .filter((element) => isUnattached("total_calculator", element))
    .map((element) => setAttached("total_calculator", element))
    .forEach((element) => {
      const shippingCost = document.querySelector(
        "#purchase_order_shipping_cost"
      );
      shippingCost.addEventListener("change", handleGlobalChange);
      shippingCost.addEventListener("keyup", handleGlobalChange);
    });

  findAll(document, ".gk-total-calculator .gk-subtotal-item")
    .filter((element) => isUnattached("total_calculator", element))
    .map((element) => setAttached("total_calculator", element))
    .forEach((element) => {
      const quantity = element.querySelector(".gk-subtotal-quantity");
      const cost = element.querySelector(".gk-subtotal-cost");
      const revenue = element.querySelector(".gk-subtotal-revenue");
      const duty = element.querySelector(".gk-subtotal-duty");
      const dutyCost = element.querySelector(".gk-subtotal-duty-cost");

      quantity.addEventListener("change", handleChange);
      cost.addEventListener("change", handleChange);
      revenue.addEventListener("change", handleChange);
      duty.addEventListener("change", handleChange);
      dutyCost.addEventListener("change", handleChange);
      quantity.addEventListener("keyup", handleChange);
      cost.addEventListener("keyup", handleChange);
      revenue.addEventListener("keyup", handleChange);
      duty.addEventListener("keyup", handleChange);
      dutyCost.addEventListener("keyup", handleChange);

      handleChange({ target: quantity });
    });
};
