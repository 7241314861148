import { findAll, isUnattached, setAttached } from "./dom";
import { isEmpty } from "lodash";

const showActive = (element, target) =>
  findAll(element, ".gk-radio-group-option").forEach((option) => {
    if (option.getAttribute("data-gk-value") === target.value) {
      option.classList.add("active");
    } else {
      option.classList.remove("active");
    }
  });

const attach = (element) => {
  const target = document.querySelector(element.getAttribute("data-gk-target"));
  const defaultOption = element.querySelector(".gk-radio-group-default");

  if (isEmpty(target.value) && defaultOption) {
    target.value = defaultOption.getAttribute("data-gk-value");
  }

  findAll(element, ".gk-radio-group-option").forEach((option) => {
    option.addEventListener("click", () => {
      target.value = option.getAttribute("data-gk-value");
      showActive(element, target);
    });
  });

  showActive(element, target);
};

export default () =>
  findAll(document, ".gk-radio-group")
    .filter((element) => isUnattached("radio_group", element))
    .map((element) => setAttached("radio_group", element))
    .forEach(attach);
