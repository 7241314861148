import { findAll, isUnattached, setAttached } from "./dom";

const handler = (event) => {
  event.stopPropagation();
  event.preventDefault();

  const source = document.querySelector(event.target.dataset.gkClone);
  const index = source.dataset.gkCloneIndex;
  const nextIndex = source.dataset.gkCloneNextIndex;

  let content = source.innerHTML;

  content = content.replace(
    new RegExp(`\\[${index}\\]`, "g"),
    `[${nextIndex}]`
  );

  content = content.replace(
    new RegExp(`_${index}_`, "g"),
    `_${nextIndex}_`
  );

  source.insertAdjacentHTML("beforebegin", content);

  const clone = source.previousElementSibling;

  clone.querySelectorAll("input, select, textarea").forEach((element) => {
    element.value = "";
  });

  source.dataset.gkCloneNextIndex = parseInt(nextIndex) + 1;
  window.Jero.attach();
};

export default () =>
  findAll(document, "[data-gk-clone]")
    .filter((element) => isUnattached("clone", element))
    .map((element) => setAttached("clone", element))
    .forEach((element) => {
      element.addEventListener("click", handler);
    });
