import $ from "jquery";
import { throttle } from "lodash";
import { findAll, isUnattached, setAttached } from "./dom";

const handler = throttle(
  (event) => {
    const params = {
      quantity: $("#order_request_abs_quantity").val(),
    };

    if ($("#order_request_unit_price").length > 0) {
      params.unit_price = $("#order_request_unit_price").val();
    }

    $.get(
      $("#order_request_abs_quantity").data("gk-calculator-url"),
      params,
      (data) => $("#gk-cost-table").html(data)
    );
  },
  500,
  { leading: false, trailing: true }
);

const attach = (element) => {
  element.addEventListener("input", handler);
  element.addEventListener("change", handler);
  element.addEventListener("keydown", handler);

  const unit_price = document.getElementById("order_request_unit_price");

  if (unit_price) {
    unit_price.addEventListener("input", handler);
    unit_price.addEventListener("change", handler);
    unit_price.addEventListener("keydown", handler);
  }
};

export default () =>
  findAll(document, ".gk-calculator")
    .filter((element) => isUnattached("calculator", element))
    .map((element) => setAttached("calculator", element))
    .forEach(attach);
