import $ from "jquery";
import { findAll, isUnattached, setAttached } from "./dom";
import { v4 as uuidv4 } from "uuid";

const attach = (element) => {
  const caption = element.querySelector(".gk-expand-caption");
  const url = element.getAttribute("data-gk-url");
  const content = element.querySelector(".gk-expand-content");
  let loaded = false;

  if (!url && !content.children.length) {
    element.outerHTML = caption.innerHTML;
    return;
  }

  const id = `gk-expand-${uuidv4()}`;

  content.classList.add("collapse");
  content.setAttribute("id", id);

  caption.setAttribute("data-bs-toggle", "collapse");
  caption.setAttribute("href", `#${id}`);

  const icon = document.createElement("i");
  icon.classList.add("fas");
  icon.classList.add("fa-fw");
  icon.classList.add("fa-xs");
  icon.classList.add("fa-chevron-down");
  icon.classList.add("me-1");
  icon.classList.add("text-muted");
  caption.prepend(icon);

  content.addEventListener("show.bs.collapse", () => {
    icon.classList.remove("fa-chevron-down");
    icon.classList.add("fa-chevron-up");

    if (url && !loaded) {
      $(content).load(url);
      loaded = true;
    }
  });

  content.addEventListener("hide.bs.collapse", () => {
    icon.classList.add("fa-chevron-down");
    icon.classList.remove("fa-chevron-up");
  });
};

export default () =>
  findAll(document, ".gk-expand")
    .filter((element) => isUnattached("expand", element))
    .map((element) => setAttached("expand", element))
    .forEach(attach);
