import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["acceptButton", "toggle"];

  initialize() {
    this.update();

    this.toggleTargets.forEach((toggle) => {
      toggle.addEventListener("click", () => {
        if (toggle.dataset.checked === "true") {
          toggle.dataset.checked = "";
        } else {
          toggle.dataset.checked = "true";
        }

        this.update();
      });
    });
  }

  update() {
    this.toggleTargets.forEach((toggle) => {
      if (toggle.dataset.checked === "true") {
        toggle.classList.add("bg-blue-500");
        toggle.classList.remove("bg-gray-200");
        toggle.querySelector("span").classList.remove("translate-x-0");
        toggle.querySelector("span").classList.add("translate-x-5");
      } else {
        toggle.classList.remove("bg-blue-500");
        toggle.classList.add("bg-gray-200");
        toggle.querySelector("span").classList.add("translate-x-0");
        toggle.querySelector("span").classList.remove("translate-x-5");
      }
    });

    if (
      this.toggleTargets.some((toggle) => toggle.dataset.checked === "true")
    ) {
      const consent = this.toggleTargets
        .filter((toggle) => toggle.dataset.checked === "true")
        .map((toggle) => toggle.dataset.value)
        .join(",");

      const url = new URL(window.location.href);
      url.searchParams.set("consent", consent);
      this.acceptButtonTarget.setAttribute("href", url);

      this.acceptButtonTarget.innerHTML = "SAVE SETTINGS";
    } else {
      const url = new URL(window.location.href);
      url.searchParams.set("consent", "reject");
      this.acceptButtonTarget.setAttribute("href", url);

      this.acceptButtonTarget.innerHTML = "REJECT ALL";
    }
  }

  persist() {
    this.toggleTargets
      .filter((toggle) => toggle.dataset.checked === "true")
      .map((toggle) => toggle.dataset.value)
      .forEach((value) => {
        window.dataLayer.push({
          event: `cookie_cat_${value}`,
        });
      });
  }

  persist_all() {
    this.toggleTargets
      .map((toggle) => toggle.dataset.value)
      .forEach((value) => {
        window.dataLayer.push({
          event: `cookie_cat_${value}`,
        });
      });
  }
}
