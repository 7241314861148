import { Modal } from "bootstrap";
import { findAll, isUnattached, setAttached } from "./dom";

export default () =>
  findAll(document, ".modal")
    .filter((element) => isUnattached("modals", element))
    .map((element) => setAttached("modals", element))
    .forEach((modal) => {
      findAll(document, ".modal-backdrop").forEach((backdrop) =>
        backdrop.remove()
      );

      const instance = new Modal(modal, {
        keyboard: false,
        backdrop: "static"
      });
      modal.addEventListener("hidden.bs.modal", () => {
        instance.dispose();
        modal.remove();
        document.body.removeAttribute("style");
      });
      findAll(modal, "form[data-gk-submit=dismiss]").forEach((form) =>
        form.addEventListener("turbo:submit-end", () => instance.hide())
      );
      instance.show();
    });
