import { findAll, isUnattached, setAttached } from "./dom";
import { debounce } from "lodash";

const onInput = (event) => {
  if (event.target.dataset.gkAutosubmitButton) {
    document.querySelector(event.target.dataset.gkAutosubmitButton).click();
  } else {
    const form = event.target.closest("form");
    const submit = form && form.querySelector("input[type=submit]");

    if (submit) {
      submit.click();
    } else {
      form.submit();
    }
  }
};

const onInputDebounced = debounce(onInput, 1000);

export default () =>
  findAll(document, ".gk-autosubmit")
    .filter((element) => isUnattached("autosubmit", element))
    .map((element) => setAttached("autosubmit", element))
    .forEach((element) =>
      element.addEventListener(
        "input",
        element.tagName === "SELECT" ||
          element.classList.contains("gk-datepicker")
          ? onInput
          : onInputDebounced
      )
    );
