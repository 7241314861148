import { findAll, isUnattached, setAttached } from "./dom";

const detectState = (element) => {
  if (element.value) {
    element.classList.remove("gk-is-empty");
  } else {
    element.classList.add("gk-is-empty");
  }
};

const onInput = (event) => detectState(event.target);

export default () =>
  findAll(document, ".gk-floating-placeholder .form-select")
    .filter((element) => isUnattached("select", element))
    .map((element) => setAttached("select", element))
    .forEach((element) => {
      element.addEventListener("input", onInput);
      detectState(element);
    });
