import { findAll, isUnattached, setAttached } from "./dom";

const handler = (element) => {
  if (element.checked) {
    if (element.dataset.gkCheckedHide) {
      const target = document.querySelector(
        element.dataset.gkCheckedHide
      );
      target.classList.add("d-none");
    }

    if (element.dataset.gkCheckedShow) {
      const target = document.querySelector(
        element.dataset.gkCheckedShow
      );
      target.classList.remove("d-none");
    }
  }
};

export default () =>
  findAll(document, "[data-gk-checked-hide],[data-gk-checked-show]")
    .filter((element) => isUnattached("checked", element))
    .map((element) => setAttached("checked", element))
    .forEach((element) => {
      element.addEventListener("input", () => {
        handler(element);
      });
      handler(element);
    });
