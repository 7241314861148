import { findAll, isUnattached, setAttached } from "./dom";

export default () =>
  findAll(document, ".gk-spin")
    .filter((element) => isUnattached("spin", element))
    .map((element) => setAttached("spin", element))
    .forEach((element) => {
      element.addEventListener("click", () => {
        const wrapper = document.createElement("div");
        wrapper.style.position = "fixed";
        wrapper.style.top = "0";
        wrapper.style.left = "0";
        wrapper.style.width = "100%";
        wrapper.style.height = "100%";
        wrapper.style.backgroundColor = "rgba(0, 0, 0, 0.5)";
        wrapper.style.zIndex = "9999";
        wrapper.style.display = "flex";
        wrapper.style.flexDirection = "column";
        wrapper.style.justifyContent = "center";
        wrapper.style.alignItems = "center";
        wrapper.style.color = "white";
        wrapper.style.fontSize = "1.5rem";
        wrapper.style.fontWeight = "bold";

        const spinner = document.createElement("div");
        spinner.classList.add("spinner-border");

        const caption = document.createElement("div");
        caption.innerText = "Please wait...";

        wrapper.appendChild(spinner);
        wrapper.appendChild(caption);

        document.body.appendChild(wrapper);
      });
    });
