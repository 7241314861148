import { Toast } from "bootstrap";
import { findAll, isUnattached, setAttached } from "./dom";

export default () =>
  findAll(document, ".toast")
    .filter((element) => isUnattached("toasts", element))
    .map((element) => setAttached("toasts", element))
    .forEach((toast) => {
      const instance = new Toast(toast, { delay: 5000 });
      toast.addEventListener("hidden.bs.toast", () => {
        instance.dispose();
        toast.remove();
      });
      toast.addEventListener("click", () => instance.hide());
      instance.show();
    });
