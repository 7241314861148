import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["overlay"];

  open() {
    this.overlayTarget.classList.add("visible");
    this.overlayTarget.classList.remove("invisible");
    this.overlayTarget.classList.remove("-translate-y-8");
    this.overlayTarget.classList.add("opacity-100");
    this.overlayTarget.classList.remove("opacity-0");
    document.body.style.overflow = "hidden";
  }

  close() {
    this.overlayTarget.classList.add("invisible");
    this.overlayTarget.classList.remove("visible");
    this.overlayTarget.classList.add("-translate-y-8");
    this.overlayTarget.classList.remove("opacity-100");
    this.overlayTarget.classList.add("opacity-0");
    document.body.style.overflow = "auto";
  }
}
