import { Popover } from "bootstrap";
import { findAll, isUnattached, setAttached } from "./dom";

const getContent = (element) => {
  const content = document.querySelector(
    element.getAttribute("data-bs-content-element")
  );
  content.parentNode.removeChild(content);
  content.removeAttribute("id");
  content.style.removeProperty("display");
  return content;
};

export default () =>
  findAll(document, '[data-bs-toggle="popover"]')
    .filter((element) => isUnattached("popovers", element))
    .map((element) => setAttached("popovers", element))
    .forEach((element) => {
      const instance = new Popover(element, {
        html: true,
        content: getContent(element),
        container: element.parentNode,
      });

      instance._config.content.addEventListener("click", (event) =>
        event.target.getAttribute("data-bs-dismiss") == "popover"
          ? instance.hide()
          : true
      );

      const clickOutsideHandler = (event) => {
        if (element.contains(event.target)) {
          return true;
        }
        if (instance && instance.tip && instance.tip.contains(event.target)) {
          return true;
        }
        if (instance) {
          instance.hide();
        }
      };

      element.addEventListener("shown.bs.popover", () =>
        document.addEventListener("click", clickOutsideHandler)
      );
      element.addEventListener("hidden.bs.popover", () =>
        document.removeEventListener("click", clickOutsideHandler)
      );
    });
