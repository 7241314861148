import { findAll, isUnattached, setAttached } from "./dom";

const handleClick = (event) => {
  if (
    event.target.tagName == "TD" &&
    event.target.firstElementChild.tagName == "A"
  ) {
    event.target.firstElementChild.click();
  }
};

export default () =>
  findAll(document, "tr.gk-link")
    .filter((element) => isUnattached("tables", element))
    .map((element) => setAttached("tables", element))
    .forEach((row) =>
      findAll(row, "td:not(.gk-nolink)").forEach((cell) =>
        cell.addEventListener("click", handleClick)
      )
    );
