import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["logoWrapper", "logoSimple", "logoPayoff"];

  connect() {
    this.scrollHandler = this.scroll.bind(this);
    this.scrollHandler();

    window.addEventListener("scroll", this.scrollHandler);
  }

  disconnect() {
    window.removeEventListener("scroll", this.scrollHandler);
  }

  scroll() {
    if (window.scrollY > 100) {
      this.element.style.height = "120px";
      this.element.style.backgroundColor = "rgba(255, 255, 255, 0.9)";
      this.logoWrapperTarget.style.height = "40px";
      this.logoWrapperTarget.style.width = "90px";
      this.logoSimpleTarget.style.opacity = "1";
      this.logoSimpleTarget.style.top = "0px";
      this.logoPayoffTarget.style.opacity = "0";
      this.logoPayoffTarget.style.top = "0px";
    } else {
      this.element.style.height = "210px";
      this.element.style.backgroundColor = "rgba(255, 255, 255, 0)";
      this.logoWrapperTarget.style.height = "100px";
      this.logoWrapperTarget.style.width = "150px";
      this.logoSimpleTarget.style.opacity = "0";
      this.logoSimpleTarget.style.top = "40px";
      this.logoPayoffTarget.style.opacity = "1";
      this.logoPayoffTarget.style.top = "40px";
    }
  }
}
