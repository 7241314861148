import { findAll, isUnattached, setAttached } from "./dom";

export default () =>
  findAll(document, "a[href^='tel:'], a[href^='mailto:']")
    .filter((element) => isUnattached("datalayer", element))
    .map((element) => setAttached("datalayer", element))
    .forEach((element) => {
      if (element.href.startsWith("tel:")) {
        element.addEventListener("click", () => {
          window.dataLayer.push({
            event: "phone_click",
            label: element.innerText,
            email: element.href.slice(4),
          });
        });
      }
      if (element.href.startsWith("mailto:")) {
        element.addEventListener("click", () => {
          window.dataLayer.push({
            event: "email_click",
            label: element.innerText,
            email: element.href.slice(7),
          });
        });
      }
    });
