import Chart from "chart.js/auto";
import colorLib from "@kurkle/color";
import { da } from "date-fns/locale";
import { findAll, isUnattached, setAttached } from "./dom";
import { get, set } from "lodash";

const numberFormat = new Intl.NumberFormat("da-DK", {
  maximumFractionDigits: 2,
});

function transparentize(value, opacity) {
  var alpha = opacity === undefined ? 0.5 : 1 - opacity;
  return colorLib(value).alpha(alpha).rgbString();
}

export default () =>
  findAll(document, "canvas.gk-chart")
    .filter((element) => isUnattached("chart", element))
    .map((element) => setAttached("chart", element))
    .forEach((element) => {
      const data = JSON.parse(element.querySelector("script").textContent);
      element.querySelector("script").remove();

      data.data.datasets.forEach(
        (dataset) =>
          (dataset.backgroundColor = transparentize(dataset.borderColor, 0.5))
      );
      set(data, "options.scales.x.adapters.date.locale", da);
      set(
        data,
        "options.plugins.tooltip.callbacks.label",
        (context) =>
          ` ${context.dataset.label}: ${
            context.dataset.yAxisID === "yPercent"
              ? ""
              : data._currencySymbolPre
          }${numberFormat.format(context.parsed.y)}${
            context.dataset.yAxisID === "yPercent"
              ? "%"
              : data._currencySymbolPost
          }`
      );
      set(
        data,
        "options.scales.y.ticks.callback",
        (value) =>
          `${data._currencySymbolPre}${numberFormat.format(value)}${
            data._currencySymbolPost
          }`
      );
      if (get(data, "options.scales.yPercent")) {
        set(
          data,
          "options.scales.yPercent.ticks.callback",
          (value) => `${numberFormat.format(value)}%`
        );
      }

      new Chart(element, data);
    });
