// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("lib/turbo");
require("@rails/activestorage").start();
require("channels");

require("trix");
require("@rails/actiontext");

require("@popperjs/core");
require("chart.js");
require("chartjs-adapter-date-fns");

require.context("../images", true);
require("../stylesheets");
require("jero").default.start();
require("controllers/application");
