const setAttached = (scope, element) => {
  element.setAttribute(`data-gk-attached-${scope}`, "true");
  return element;
};

const isAttached = (scope, element) =>
  element.getAttribute(`data-gk-attached-${scope}`) == "true";

const isUnattached = (scope, element) => !isAttached(scope, element);

const findAll = (root, selector) => [...root.querySelectorAll(selector)];

export { setAttached, isAttached, isUnattached, findAll };
