import { findAll, isUnattached, setAttached } from "./dom";

const changeHandler = (
  valuationUrl,
  wineInput,
  vintageInput,
  volumeInput,
  targetInput,
  currency
) => {
  if (
    wineInput.value &&
    vintageInput.value &&
    volumeInput.value &&
    currency &&
    targetInput.value === ""
  ) {
    fetch(
      valuationUrl +
        "?" +
        new URLSearchParams({
          wine_id: wineInput.value,
          vintage: vintageInput.value,
          volume: volumeInput.value,
          currency_id: currency,
        })
    ).then((response) => {
      if (response.ok) {
        response.json().then((data) => {
          if (data.valuation > 0.0) {
            targetInput.value = data.valuation;
          }
        });
      }
    });
  }
};

export default () =>
  findAll(document, ".gk-valuation")
    .filter((element) => isUnattached("valuation", element))
    .map((element) => setAttached("valuation", element))
    .forEach((element) => {
      const valuationUrl = element.dataset.valuationUrl;
      const wineInput = element.querySelector(".gk-valuation-wine-id");
      const vintageInput = element.querySelector(".gk-valuation-vintage");
      const volumeInput = element.querySelector(".gk-valuation-volume");
      const targetInput = element.querySelector(".gk-valuation-target");
      const currency = element.dataset.valuationCurrency;

      wineInput.addEventListener("input", () =>
        changeHandler(
          valuationUrl,
          wineInput,
          vintageInput,
          volumeInput,
          targetInput,
          currency
        )
      );
      vintageInput.addEventListener("input", () =>
        changeHandler(
          valuationUrl,
          wineInput,
          vintageInput,
          volumeInput,
          targetInput,
          currency
        )
      );
      volumeInput.addEventListener("input", () =>
        changeHandler(
          valuationUrl,
          wineInput,
          vintageInput,
          volumeInput,
          targetInput,
          currency
        )
      );
    });
