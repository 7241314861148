import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.scrollHandler = this.scroll.bind(this);
    this.scrollHandler();

    window.addEventListener("scroll", this.scrollHandler);
  }

  disconnect() {
    window.removeEventListener("scroll", this.scrollHandler);
  }

  scroll() {
    const top = 175 - window.scrollY * 0.25;
    this.element.style.top = `${top}px`;
  }
}
