import { findAll, isUnattached, setAttached } from "./dom";

export default () =>
  findAll(document, "[data-gk-disable-from]")
    .filter((element) => isUnattached("disable-from", element))
    .map((element) => setAttached("disable-from", element))
    .forEach((element) => {
      const target = document.querySelector(element.dataset.gkDisableFrom);
      if (!target) return;

      const toggle = () => {
        if (target.value === element.dataset.gkDisableFromValue) {
          element.disabled = true;
          element.value = "";
        } else {
          element.disabled = false;
        }
      };

      target.addEventListener("change", toggle);
      toggle();
    });
