import { findAll, isUnattached, setAttached } from "./dom";

const setTitle = (form) => {
  const submit = form.querySelector("input[type=submit]");
  console.log(submit);

  if (
    findAll(form, "input:not([type=submit]),select").some(
      (element) => element.value
    )
  ) {
    submit.value = form.getAttribute("data-gk-submit-title");
  } else {
    submit.value = form.getAttribute("data-gk-submit-title-original");
  }

  submit.setAttribute("data-disable-with", submit.value);
};

const onInput = (event) => setTitle(event.target.closest("form"));

export default () =>
  findAll(document, "form[data-gk-submit-title]")
    .filter((element) => isUnattached("submit_title", element))
    .map((element) => setAttached("submit_title", element))
    .forEach((element) => {
      const submit = element.querySelector("input[type=submit]");
      element.setAttribute("data-gk-submit-title-original", submit.value);
      element.addEventListener("input", onInput);
      setTitle(element);
    });
