import $ from "jquery";
import select2 from "select2";
import { isEmpty, isNil } from "lodash";
import { findAll, isUnattached, setAttached } from "./dom";

const toggleEmptyClass = (element) =>
  $(element)
    .parent()
    .children(".select2")
    .toggleClass(
      "select2-empty",
      isNil($(element).val()) || isEmpty($(element).val())
    );

const syncInvalidClass = (element) =>
  $(element)
    .parent()
    .children(".select2")
    .toggleClass("is-invalid", $(element).hasClass("is-invalid"));

const removeInvalidClass = (element) =>
  $(element).parent().children(".select2").removeClass("is-invalid");

export default () =>
  findAll(document, "select[data-gk-select2]")
    .filter((element) => isUnattached("select2", element))
    .map((element) => setAttached("select2", element))
    .forEach((element) => {
      const options = {
        theme: "bootstrap5",
        language: {
          errorLoading: () => "Resultaterne kunne ikke indlæses.",
          inputTooLong: (args) =>
            "Angiv venligst " +
            (args.input.length - args.maximum) +
            " tegn mindre",
          inputTooShort: (args) =>
            "Angiv venligst " +
            (args.minimum - args.input.length) +
            " tegn mere",
          loadingMore: () => "Indlæser flere resultater…",
          maximumSelected: (args) =>
            "Du kan kun vælge " +
            args.maximum +
            " emne" +
            (args.maximum != 1 ? "r" : ""),
          noResults: () => "Ingen resultater fundet",
          searching: () => "Søger…",
          removeAllItems: () => "Fjern alle elementer",
        },
        allowClear: true,
        placeholder: { id: "" },
        dropdownAutoWidth: true,
        ajax: {
          url: element.getAttribute("data-gk-select2"),
          dataType: "json",
          delay: 250,
          data: (params) => ({ q: params.term, page: params.page }),
          cache: true,
        },
        escapeMarkup: (markup) => markup,
        templateResult: (data) =>
          isNil(data.html)
            ? $.fn.select2.defaults.defaults.escapeMarkup(data.text)
            : data.html,
        templateSelection: (data) =>
          $.fn.select2.defaults.defaults.escapeMarkup(data.text),
      };
      if ($(element).closest(".modal-body").length > 0) {
        options.dropdownParent = $(element).closest(".modal-body");
      }
      $(element).select2(options);
      toggleEmptyClass(element);
      syncInvalidClass(element);
      $(element).on("select2:select select2:unselect select2:clear", () => {
        toggleEmptyClass(element);
        removeInvalidClass(element);
        element.dispatchEvent(
          new Event("input", {
            bubbles: true,
            cancelable: true,
          })
        );
      });
      $(element).on("select2:open", () => {
        const fields = $(".select2-search__field").first();
        if (fields.length > 0) {
          fields[0].focus();
        }
      });
    });
