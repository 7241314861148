import { findAll, isUnattached, setAttached } from "./dom";

export default () =>
  findAll(document, ".gk-meta")
    .filter((element) => isUnattached("meta", element))
    .map((element) => setAttached("meta", element))
    .forEach((meta) => {
      if (meta.dataset.gkRedirect) {
        window.location.replace(meta.dataset.gkRedirect);
      }
    });
